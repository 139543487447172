<template>
	<div class="row">
		<div class="col-12">
			<div class="row row-cols-xl-3">
				<div class="col-12">

               <div class="card">
                  <div class="body">
                      <article class="media mb-0">
                          <div class="media-body">
                              <div class="command_class">
                                 <div class="">/deck jokenpo</div>
                              </div>
                              <nav class="d-flex">
                                 <img class="rounded" src="@/assets/images/pedra.png" alt="" style="width: 50px;">
                                 <img class="rounded" src="@/assets/images/papel.png" alt="" style="width: 50px;">
                                 <img class="rounded" src="@/assets/images/tesoura.png" alt="" style="width: 50px;">
                              </nav>
                          </div>
                      </article>
                  </div>
              </div>
               
					
				</div>

				<div class="col-12">

               <!-- <div class="card">
                  <div class="body">
                      <article class="media mb-0">
                          <div class="media-body">
                              <div class="command_class">
                                 <div class="">!link</div>
                              </div>
                              <div class="command_class mt-2">
                                 <div class="">!site</div>
                              </div>
                          </div>
                      </article>
                  </div>
              </div> -->
              
				</div>

				<div class="col-12">
               
					
				</div>
			</div>

   
			
		</div>
	</div>
</template>

<script>

// import $ from 'jquery'
import { mapState } from 'vuex';
// import MessageDeck from '@/components/MessageDeck.vue'
// import PreviewMessageDeck from '@/components/PreviewMessageDeck.vue'

export default {
	name: 'General_Commands',
	data: function () {
		return {
			
		}
	},
	computed: {
		...mapState({
			dadosUsuario: state => state.dadosUsuario,
			channelsList: state => state.channelsList,
			serverS: state => state.serverS,
			urlRest: state => state.urlRest,
		}),
	},
	components: {
		// MessageDeck, PreviewMessageDeck
	},
	methods: {
		
   },
	mounted() {
		
	}
}

</script>

<style>

.command_class {
   background-color: #181A1C;
   color: azure;
   font-family: 'Courier New', Courier, monospace;
   font-size: 16px;
   padding: 4px 8px;
   border-radius: 5px;
}


</style>